<template>
	<div id="app" :class="content.theme_class">
		<header-component :top-banner-text=content.topbanner_text :top-banner-link=content.topbanner_link />
		<hero-component
			:start-page="true"
			:heading=content.title
			:paragraph=content.paragraph
			:with-drop-down="true"
			:bg-image="content.background ? baseApiUrl + content.background : null"
			:video-bg="content.background ? baseApiUrl + content.background : null"
		/>
	
		<section class="black-bar">
			<div class="section-content-wrapper">
				<div>
					<div class="number">{{openFacilities.length}}</div>
					<p>Padelcenter</p>
				</div>
				<div>
					<div class="number">{{allClosedFacilities.length}}</div>
					<p>Kommande padelcenter</p>
				</div>
				<div>
					<div class="number">{{partnerLanes}}</div>
					<p>Padelbanor totalt</p>
				</div>
			</div>
		</section>
		<section class="grid">
			<div class="section-content-wrapper">
				<div class="grid-heading-wrapper">
					<h1>{{content.main_body_title}}</h1>
					<div v-html="content.main_body_paragraph"></div>
				</div>
				<p class="grid-title">öppnade</p>
				<div class="grid-container">
					<facility-card
						v-for="(item, i) in openFacilities"
						:key="i"
						:data="item"
						:iscoming="false"
					/>
				</div>
				<p class="grid-title">kommande center</p>
				<div class="grid-container">
					<facility-card
						v-for="(item, i) in closedFacilities"
						:key="i"
						:data="item"
						:iscoming="true"
					/>
				</div>
				<div class="btn-wrapper">
					<a href="/vara-center" class="btn-show-all">visa alla center</a>
				</div>
			</div>
		</section>

		<section v-if="content.partnercenter_title" class="our-centres" style="background-color: rgb(0,0,0);color: #FFF;">
			<div class="section-content-wrapper">
				<div>
					
					<h2>{{content.partnercenter_title}}</h2>
					<div v-html="content.partnercenter_paragraph"></div>
					<a href="/vara-center#Life" class="btn-show-all" style="color:#FFF;border-color:#FFF"
					>{{content.partnercenter_button_text}}</a
					>
				</div>
			</div>
		</section>
		<div v-for="item in content.nested_content" :key="item.title">
			<text-image v-if="!item.is_center_block"
				:image-pos=item.image_pos
				:image-url=item.image
				:heading=item.title
				:wide-image=item.wide_image
				:paragraph=item.ingress
				:button=item.button
				:button-item=item.button
				:bg-color=item.bg_color
				:text-color=item.text_color
			/>
			<text-center v-else-if="item.is_center_block"
				:heading=item.title
				:paragraph=item.ingress
				:bg-color=item.bg_color
				:text-color=item.text_color
				:button=item.button
				:button-item=item.button
			/>
		</div>
		<footer-component />
	</div>
</template>
<script>
//import facilities from '@/assets/facilities.json'
import axios from 'axios'
import { baseUrl } from '@/assets/apiLink.js'
export default {
	name: 'Start',
	components: {
		'header-component': () => import('@/components/Header.vue'),
		'hero-component': () => import('@/components/Hero.vue'),
		'footer-component': () => import('@/components/Footer.vue'),
		'facility-card': () => import('@/components/FacilityCard.vue'),
		'text-image': () => import('@/components/TextImage.vue'),
		'text-center': () => import('@/components/TextCenter.vue'),
	},
	data: function () {
		return {
			openFacilities: {},
			closedFacilities: {},
			allClosedFacilities: {},
			lanes: 0,
			partnerLanes: 0,
			baseApiUrl: baseUrl,
			content: {},
		}
	},
	mounted() {
		axios.get(this.baseApiUrl + '/umbraco/api/homepage/getclubs').then((response) => {
			let facilities = response.data

			this.openFacilities = facilities
				.filter((item) => item.status.open)
				.filter((item) => item.categories[0].toLowerCase() == 'nordic wellness padelcenter')
				.sort((item1, item2) => {
					if (item1.location < item2.location) {
						return -1
					}
					if (item1.location > item2.location) {
						return 1
					}
					return 0
				})
			this.allClosedFacilities = facilities.filter((item) => !item.status.open)

			this.closedFacilities = this.allClosedFacilities
				.filter((item) => !item.status.open)
				.slice(0, 6)

			this.lanes = facilities.map((v) => v.lanes).reduce((a, b) => a + b)

			this.partnerLanes = this.lanes
		})

		axios.get(this.baseApiUrl + '/umbraco/api/homepage/getcontent/').then((response) => {
			this.content = response.data
			console.log(this.content.main_body_paragraph)
			this.content.paragraph = this.content.main_body_paragraph
				? this.content.main_body_paragraph
				: 'På Nordic Wellness Padel har du tillgång till moderna padelbanor i anslutning till våra gym, vilket gör att du enkelt kan kombinera padeln med annan träning.<br>Boka en bana via Matchi, hyr padelrack och köp bollar och diverse tillbehör på plats!<br>Som medlem hos Nordic Wellness har du 20% rabatt på alla speltider (lokala avvikelser kan förekomma).'
			/*if (!this.content.hasOwnProperty('paragraph')) {
				this.content.paragraph =
					'Just nu får du 3 speltillfällen, 60 eller 90 minuter långa, för endast 749 kr – en perfekt julklapp till dig själv eller någon du tycker om! Välkommen att boka våra fräscha banor som alltid hittas i anslutning till våra klubbar. Välj din ort för att komma till erbjudandet.'
			}*/
		})
	},
}
</script>

<style lang="scss">
.hero h1 {
	@include h1-hero-start;
}
.black-bar {
	padding: 60px 60px;
	background-color: black;
	@include max-width(small) {
		padding: 30px 30px;
	}
	.section-content-wrapper {
		align-content: space-between;
		& > div {
			flex: 1;
			text-align: center;
			color: white;
		}
		.number {
			font-family: 'Abolition', sans-serif;
			font-weight: normal;
			font-size: 64px;
			line-height: 64px;
			letter-spacing: normal;
			margin-bottom: 12px;
			@include max-width(small) {
				font-size: 32px;
				line-height: 36px;
			}
		}
		p {
			text-transform: uppercase;
			font-family: $secondary-font-sbold;
			@include max-width(small) {
				font-size: 12px;
				line-height: 18px;
			}
		}
	}
}
.grid {
	background-color: $color-bg-grey;

	.section-content-wrapper {
		flex-direction: column;
		align-items: center;

		.grid-title {
			@include paragraph-small;

			font-family: $secondary-font-sbold;
			align-self: start;
			text-transform: uppercase;
		}
		.btn-wrapper {
			display: flex;
			justify-content: center;
			width: 100%;
			padding-top: 100px;

			a {
				min-width: max-content;
			}

			@include max-width(small) {
				padding-top: 55px;
			}
		}
		.grid-heading-wrapper {
			text-align: center;
			max-width: 650px;

			h1 {
				margin-bottom: 28px;
			}

			p {
				margin-bottom: 40px;
			}
		}

		.grid-container {
			@include card-grid;

			width: 100%;
		}

		& > .grid-title:nth-of-type(2) {
			margin-top: 30px;
			margin-bottom: 5px;
		}
	}
}

.btn-show-all {
	@include paragraph-small;

	text-align: center;
	font-family: $secondary-font-sbold;
	line-height: normal;
	color: #000;
	text-transform: uppercase;
	text-decoration: none;
	border: 2px solid #000;
	border-radius: 24px;
	padding: 15px 45px;
}

.our-centres {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 130px;
	padding-bottom: 120px;
	.section-content-wrapper {
		justify-content: center;

		div {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 560px;

			h2 {
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 20px;
				line-height: 54px;
			}

			p {
				text-align: center;
				margin-bottom: 38px;
				@include max-width(small) {
					text-align: center;
				}
			}
		}
	}
}
.text-image + .text-image {
	padding-top: 0;
}

.pinkRibbonTheme {
	.black-bar {
		background-color: $color-darkpink !important;
	}
}
</style>
